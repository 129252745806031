import mixpanel from 'mixpanel-browser';

const options = {
	api_host: process.env.NEXT_PUBLIC_MIXPANEL_API_HOST!,
	debug: process.env.NODE_ENV === "development",
	track_pageview: true,
	record_mask_text_selector: ".mask-text",
};

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, options);

export default mixpanel;
